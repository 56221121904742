<template>
    <div class="checkbox_parent input_parent form_checkbox form_checkbox--card" :class="{ 'active': show, 'active_fake': show}">
        <label :for="field.id" @click="toggle">
            <span class="form_checkbox__label-text fake_checkbox" :class="{'checked': isChecked }">{{ field.label }}</span>
        </label>

        <transition name="slide">

        <div class="textarea_wrap inputs_group" :class="{'visible': show}" v-if="show">
            <template v-for="fieldSub in field.sub">
                <Textarea :key="fieldSub.id" v-if="fieldSub.type == 'textarea' " :field="fieldSub" ref="customInputs"></Textarea>
                <Radio :key="fieldSub.id" v-if="fieldSub.type == 'radio' " :field="fieldSub" ref="customInputs"></Radio>
                <Checkbox :key="fieldSub.id" v-if="fieldSub.type == 'checkbox' " :field="fieldSub" ref="customInputs"></Checkbox>
            </template>
        </div>
        </transition>
    </div>
</template>

<script>
import Textarea from "@/blocks/quiz/inputs/textarea";
import Radio from "@/blocks/quiz/inputs/radio";
import Checkbox from "@/blocks/quiz/inputs/checkbox";
export default {
    components: {Checkbox, Radio, Textarea},
    props: ['id', 'field'],
    data(){
        return {
            show: false
        }
    },
    mounted() {
        this.show = this.isChecked
    },
    computed: {
        value: {
            get() {
                return this.$store.state.form[this.field.name]
            },
            set(value) {
                this.$store.commit('setValue', {
                    'field': this.field.name,
                    'value': value,
                })
            }
        },

        isChecked(){
            let res = false;
            this.field.sub.forEach((item)=>{
                if (this.$store.state.form[item.name]) {
                    res = true;
                }
            })
            return res;
        },
        isSelected(){
            return this.$store.state.form[this.field.name] == this.field.value
        },
        isGray(){
            return (this.$store.state.form[this.field.name] && this.$store.state.form[this.field.name] != this.field.value)
        }
    },

    watch: {
        isSelected(value){

            //if(!value) {
            //    this.$refs.customInputs.forEach((i, k)=>{
            //        //i.clear()
            //    })
            //}
        }
    },
     methods: {
         setValue(){
             this.$store.commit('setValue', {
                 'field': this.field.name,
                 'value': this.field.value,
             })
         },
         toggle(){
             this.show = !this.show

             if(!this.show) {
                 this.field.sub.forEach((item)=>{
                     this.$store.commit('setValue', {
                         'field': item.name,
                         'value': '',
                     })
                 })
             }
         }
     }
}
</script>

<style>
.textarea_wrap.visible {
    display: block;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
.textarea_wrap .form_checkbox--card,
.textarea_wrap .form_radio--card {
    background: none;
}
</style>