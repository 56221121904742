<template>
    <div class="quiz-result__locked-hidden" >

        <section class="s_master">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div class="row">

                        <div class="col-lg-4">
                            <div class="master_wrap">
                                <img alt="master" class="master__img img-responsive lazy" :src="require('../../assets/images/i1.png')">
                                <div class="master__title">
                                    Роман Петров
                                </div>
                                <div class="master__description">
                                    Аквариус
                                </div>
                            </div>
                        </div>

                        <div class="master-text col-lg-7">
                            <p><b>Отлично!</b> Расчет стоимости ремонта вашей квартиры сформирован. </p>
                            <!--                                                                <p class="accent">Если вы хотите рассчитать стоимость ремонта других помещений, пройдите опрос еще раз.</p>-->
                            <!--                                                                <a href="index.htm#qiuz_wrap" class="button_link go_to" target="_blank">РАССЧИТАТЬ ДРУГИЕ<br> ПОМЕЩЕНИЯ</a>-->
                            <!--                                                                <p class="last_p">Нам важно, чтобы вы получили такой ремонт,<br> какой хотели.</p>-->
                        </div>

                        <div class="col-12">
                            <div class="master-list">
                                <p>Поэтому для расчета окончательной стоимости, нужен <b>выезд инженера-технолога:</b> </p>
                                <ul>
                                    <li>— выполнит точные замеры, </li>
                                    <li>— задаст уточняющие вопросы, </li>
                                    <li>— подскажет, как сэкономить без потери качества</li>
                                    <li>— расчитает окончательную стоимость, которую зафиксирует в договоре.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section class="s_cost_calc">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="s_cost-form">
                                <div class="s_cost__form-title">Дизайн-проект в подарок</div>
                                <div class="s_cost__form-text">при заказе ремонта до <span class="JSdate2">{{ date }}</span></div>

                                <div class="s_cost-download flex ">
                                    <div class="s_cost-download__img">
                                        <a href="#pdf" class="go_to"><img :src="require('../../assets/images/download_icon.svg')" class="download_icon" alt="download_icon"></a>
                                        <img alt="alt" class="header_gift__img-img img-responsive" :src="require('../../assets/images/h_book.png')">
                                    </div>
                                    <div class="s_cost-download__text">
                                        Подборку ремонтов <br> в квартирах, схожих<br> с вашей, <a href="#pdf" class="go_to"><b class="accent">скачайте внизу страницы</b></a>
                                        <small>*закреплен за номером телефона</small>
                                    </div>
                                </div>

                                <div class="change_number-text text-center">Если Вы указали номер неправильно, можете изменить его сейчас</div>
                                <form id="changeNumber">
                                    <input type="hidden" name="utm_source" value="">
                                    <input type="hidden" name="utm_medium" value="">
                                    <input type="hidden" name="utm_campaign" value="">
                                    <input type="hidden" name="utm_content" value="">
                                    <input type="hidden" name="utm_term" value="">
                                    <div class="change_number-form">
                                        <input type="hidden" name="Тема" value="Изменить номер телефона">
                                        <input type="tel" id="phone__upd" name="Телефон" class="js-phone-input js-phone-input js-change-number-phone second_input"  v-model="phone"  v-mask="{mask: '+7 (999) 999-99-99', greedy: true, 'digits': 9}" placeholder="+7 (___) ___-__-__">
                                        <button type="button" id="upd__phone" class="js-change-number__btn">Изменить номер</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <QuizSidebar class="cost_calc" :show-price="true"></QuizSidebar>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="viezd">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="viezd__subtitle flex aic"><img :src="require('../../assets/images/warning.svg')" alt="!">Разница в расчетах зависит от&nbsp;деталей:</div>
                            <ul class="viezd__list">
                                <li><span>— </span> <span><b>индивидуальных особенностей</b><br> квартиры;</span></li>
                                <li><span>— </span> <span><b>работ, которые не были учтены</b><br> в&nbsp;вопросах;</span></li>
                                <li><span>— </span> <span><b>материалов,</b> которые будете использовать в&nbsp;отделке квартиры.</span></li>
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <div class="viezd__form">
                                <div class="viezd__form-title flex aic">
                                    <div class="text">Именно поэтому нужно <b class="accent">вызвать инженера-технолога,</b> который</div>
                                    <img :src="require('../../assets/images/rule.svg')" alt="alt">
                                </div>
                                <div class="viezd__form-text">
                                    <br>— задаст уточняющие вопросы,
                                    <br>— сделает точные замеры комнат
                                    <br>— подскажет, какие матералы стоит применять в вашей квартире
                                    <br>— рассчитает точную стоимость ремонта и зафиксирует ее в договоре
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <form id="vieszd-inzhenera" class="flex aic">
                                <input type="hidden" name="utm_source" value="">
                                <input type="hidden" name="utm_medium" value="">
                                <input type="hidden" name="utm_campaign" value="">
                                <input type="hidden" name="utm_content" value="">
                                <input type="hidden" name="utm_term" value="">
                                <img :src="require('../../assets/images/arr_viezd.svg')" class="arr_viezd" alt="alt">
                                <input type="hidden" name="Тема" value="Заказать выезд">
                                <input type="text" name="Телефон" class="viezd__form-phone js-phone-input js-vieszd-inzhenera__phone second_input" v-model="phone"  v-mask="{mask: '+7 (999) 999-99-99', greedy: true, 'digits': 9}" placeholder="+7 (___) ___-__-__">
                                <button type="submit" class="viezd__form-btn js-vieszd-inzhenera__btn" id="quiz__order" @click.prevent="zakaz">Заказать выезд &gt;&gt; </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="social">
            <div class="black_title">Более <span>700 квартир и домов</span> отремонтировали на совесть наши мастера </div>
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <Slick class="social_slider slick-dotted" id="social_slider" :options="settingsSlider" @afterChange="handleAfterChange">

                        <a :href="require('../../assets/images/review/1.jpeg')" class="social_slider-item"><span class="social_slider-img"><img :src="require('../../assets/images/review/1.jpeg')" class="img-responsive lazy" alt="alt"></span></a>
                        <a :href="require('../../assets/images/review/2.jpeg')" class="social_slider-item"><span class="social_slider-img"><img :src="require('../../assets/images/review/2.jpeg')" class="img-responsive lazy" alt="alt"></span></a>
                        <a :href="require('../../assets/images/review/3.jpeg')" class="social_slider-item"><span class="social_slider-img"><img :src="require('../../assets/images/review/3.jpeg')" class="img-responsive lazy" alt="alt"></span></a>
                        <a :href="require('../../assets/images/review/4.jpeg')" class="social_slider-item"><span class="social_slider-img"><img :src="require('../../assets/images/review/4.jpeg')" class="img-responsive lazy" alt="alt"></span></a>
                        <a :href="require('../../assets/images/review/5.jpeg')" class="social_slider-item"><span class="social_slider-img"><img :src="require('../../assets/images/review/5.jpeg')" class="img-responsive lazy" alt="alt"></span></a>
                        <a :href="require('../../assets/images/review/3.jpeg')" class="social_slider-item"><span class="social_slider-img"><img :src="require('../../assets/images/review/3.jpeg')" class="img-responsive lazy" alt="alt"></span></a>>
                    </Slick>
                </div>
            </div>

        </section>

        <section id="masters" class="section nine">
            <div class="container">
                <h2 class="nine__title title">Познакомьтесь <strong>с нашими мастерами</strong></h2>
                <div class="nine-slider">
                    <Slick class="swiper nine__slider"  :options="nineSlider" ref="slick">
                        <div class="swiper-slide nine__item">
                            <figure>
                                <img :src="require('../../assets/images/nine/nine-6.png')" alt="img">
                                <h5>Иван Канатаев</h5>
                                <p>Оценка мастера</p>
                                <div class="condition">
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                </div>
                                <strong>Универсал</strong>
                                <i>Специалист широкого профиля. Выполнит как отделку помещения, так комплексный проект по ремонту помещения.</i>
                            </figure>
                        </div>
                        <div class="swiper-slide nine__item">
                            <figure>
                                <img :src="require('../../assets/images/nine/nine-5.png')" alt="img">
                                <h5>Сергей Матвеев</h5>
                                <p>Оценка мастера</p>
                                <div class="condition">
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                </div>
                                <strong>Монтажник ГКЛ</strong>
                                <i>Смонтирует подвесной потолок. Возведет стыны, арки, короба для маскировки труб. Обошьет стены гипсокартоном для утепления.</i>
                            </figure>
                        </div>
                        <div class="swiper-slide nine__item">
                            <figure>
                                <img :src="require('../../assets/images/nine/nine-1.png')" alt="img">
                                <h5>Никита Белых</h5>
                                <p>Оценка мастера</p>
                                <div class="condition">
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                </div>
                                <strong>Маляр-штукатур</strong>
                                <i>Ошпаклюет и оштукатурит стены, сделав их ровными. Может покрасить стены, поклеить на
                                    них обои либо положить плитку</i>
                            </figure>
                        </div>
                        <div class="swiper-slide nine__item">
                            <figure>
                                <img :src="require('../../assets/images/nine/nine-2.png')" alt="img">
                                <h5>Светлана Александрова</h5>
                                <p>Оценка мастера</p>
                                <div class="condition">
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                </div>
                                <strong>Маляр</strong>
                                <i>Подготовит поверхности к работам. Окрасит стены, трубы или потолок в любой цвет.</i>
                            </figure>
                        </div>
                        <div class="swiper-slide nine__item">
                            <figure>
                                <img :src="require('../../assets/images/nine/nine-3.png')" alt="img">
                                <h5>Виктор Ухалин</h5>
                                <p>Оценка мастера</p>
                                <div class="condition">
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                </div>
                                <strong>Сантехник</strong>
                                <i>Монтаж, эксплуатация и ремонт систем канализации, отопления, вентиляции, водо-, тепло- и газоснабжение.</i>
                            </figure>
                        </div>
                        <div class="swiper-slide nine__item">
                            <figure>
                                <img :src="require('../../assets/images/nine/nine-4.png')" alt="img">
                                <h5>Николай Королев</h5>
                                <p>Оценка мастера</p>
                                <div class="condition">
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                    <span class="icon-star nine__icon"></span>
                                </div>
                                <strong>Электрик</strong>
                                <i>Сборка, отладка, проводка и ремонт электрооборудования, электросети и системы.</i>
                            </figure>
                        </div>
                    </Slick>
                    <span class="nine__prev  icon-prev" @click="prev"></span>
                    <span class="nine__next icon-next" @click="next"></span>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.scss';

import QuizSidebar from "@/blocks/quiz/Sidebar";
import Slick from 'vue-slick';


export default {
    components: {QuizSidebar, Slick},
    data(){
        return {

            settingsSlider: {
                slidesToShow: 5,
                slidesToScroll: 1,
                dots: true,
                autoplay: true,
                responsive: [
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ]
            },

            nineSlider: {
                speed: 1000,
                autoHeight: true,
                grabCursor: true,
                loop: true,
                simulateTouch: true,
                navigation: {
                    nextEl: ".nine__next",
                    prevEl: ".nine__prev",
                },
                breakpoints: {
                    480: {
                        slidesPerView: 'auto',

                    },
                    576: {
                        slidesPerView: 2,

                    },
                    768: {
                        slidesPerView: 3,

                    },
                    992: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 30,

                    },
                    1100: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 30,

                    },
                },

            }
        }
    },
    computed: {
        date(){
            let today = new Date(),
                inWeek = new Date();
            inWeek.setDate(today.getDate()+7);
            let options = {
                month: 'long',
                day: 'numeric',

            };
            return inWeek.toLocaleString('ru', options)
        },
        phone: {
            get() {
                return this.$store.state.form['phone']
            },
            set(value) {
                this.$store.commit('setValue', {
                    'field': 'phone',
                    'value': value,
                })
            }
        },
    },
    methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        zakaz(){


            this.$http.post('/email-send.php', {form: this.$store.state.form}, {emulateJSON: true}).then(()=>{

                $.fancybox.open({
                    autoFocus:false,
                    transitionEffect: "fade",
                    transitionDuration: 1000,
                    src: '#quiz_thank_you',
                    type : 'inline',
                } );

                ym(88311490,'reachGoal','send-form')

                $('html, body').animate({
                    scrollTop: $(".cards-wrapper").offset().top
                }, 100);
            }, ()=>{
                alert('Ошибка отправки формы!')
            });



        },

        handleAfterChange(){
            $('.slick-slide').removeClass('slick-active-first slick-active-last slick-active-center');
            $('.slick-active').eq(1).addClass('slick-active-first');
            $('.slick-active').eq(2).addClass('slick-active-center');
            $('.slick-active').eq(3).addClass('slick-active-last');
        }

    }
}
</script>