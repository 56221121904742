<template>
    <div class="input_parent form_checkbox form_checkbox--card" :class="{'gray': isGray, 'active': isSelected}">
        <label :for="field.id" @click="setValue">
            <input class="question-4-checkbox" :id="id" :name="field.name" type="checkbox" :value="field.value" v-model="value">
            <span class="form_checkbox__label-text">{{ field.label }}</span>
        </label>

        <transition name="slide">

        <div class="textarea_wrap inputs_group"  :class="{'visible': isSelected}" v-if="isSelected">
            <template v-for="fieldSub in field.sub">
                <Textarea :key="fieldSub.id" v-if="fieldSub.type == 'textarea' " :field="fieldSub" ref="customInputs"></Textarea>
            </template>
        </div>
        </transition>
    </div>
</template>

<script>
import Textarea from "@/blocks/quiz/inputs/textarea";
export default {
    components: {Textarea},
    props: ['id', 'field'],
    data(){
        return {
        }
    },
    computed: {
        value: {
            get() {
                return this.$store.state.form[this.field.name]
            },
            set(value) {
                this.$store.commit('setValue', {
                    'field': this.field.name,
                    'value': value,
                })
            }
        },

        isSelected(){
            return this.$store.state.form[this.field.name] == this.field.value
        },
        isGray(){
            return (this.$store.state.form[this.field.name] && this.$store.state.form[this.field.name] != this.field.value)
        }
    },

    watch: {
        isSelected(value){
            if(!value && this.$refs.customInputs && this.$refs.customInputs.length) {
                this.$refs.customInputs.forEach((i, k)=>{
                    //i.clear()
                })
            }
        }
    },
     methods: {
         setValue(){

             let value = this.field.value;

             if( value == this.$store.state.form[this.field.name] ) {
                 value = '';
             }
             this.$store.commit('setValue', {
                 'field': this.field.name,
                 'value': value,
             })
         }
     }
}
</script>

<style>
.textarea_wrap.visible {
    display: block;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>