export default {
    required(value, instance){
        if(value) return true;
        return 'Обязательное поле';
    },
    validateCeilingHeight(value, instance){
        value = parseFloat(value);

        if(!value) {
            return 'Обязательное поле';
        }

        if(value < 1) {
            return 'Слишком низкие потолки';
        }
        if(value > 5) {
            return 'Высота потолков не может быть выше 5 м'
        }
        return true;
    },
    validateApartmentArea(value, instance){
        value = parseFloat(value);

        if(!value) {
            return 'Обязательное поле';
        }

        if(value < 10) {
            return 'Слишком маленькая площадь';
        }
        return true;
    },
    validateBathroomArea(value, instance){
        value = parseFloat(value);

        if(!value) {
            return 'Обязательное поле';
        }

        if(value < 1) {
            return 'Слишком маленькая площадь';
        }
        return true;
    },

    step4(value, instance){

    }
}