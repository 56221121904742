<template>
    <div>
        <textarea :id="field.id" :name="field.name" :placeholder="field.placeholder" @keydown="validateInput" @input="setValue" v-model="model" :class="[validClass]" style="vertical-align: top;"></textarea>
        <span class="error" v-if="validError">{{ validError }}</span>
    </div>
</template>

<script>
import validators from '../validators';
import formatters from '../formatters';
export default {
    props: ['field'],
    data(){
        return {
            model: ''
        }
    },
    mounted() {
        this.model = this.localValue
    },
    computed: {
        localValue(){
            return this.$store.state.form[this.field.name]
        },

        isValid(){
                if(this.field.validator && validators[this.field.validator]) {
                    return validators[this.field.validator](this.model) === true;
                }
            return false;
        },


        validClass() {
            if(!this.localValue) return '';

            if(!this.isValid){
                return 'notvalid';
            } else {
                return 'valid';
            }
        },

        validError() {
            if(!this.localValue) return '';

            if(this.field.validator && validators[this.field.validator]) {
                if(validators[this.field.validator](this.model) !== true){
                    return  validators[this.field.validator](this.model);
                };
            }
        }
    },
    methods: {
        validateInput(e){
            if(this.field.formatter && formatters[this.field.formatter] ) {
                if(!formatters[this.field.formatter](e)){
                    e.preventDefault();
                    return false;
                }
            }
        },

        setValue(){
            this.$store.commit('setValue', {
                'field': this.field.name,
                'value': this.model,
            })
        },

        clear(){
            this.model = '';
            this.setValue();
        }
    }
}
</script>