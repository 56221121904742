<template>
    <div class="quiz__question js-quiz__question--6 js-quiz__question--contact active">
        <div class="quiz__questions">
            <div class="row">

                <div class="col-lg-12">
                    <div class="quiz__question-title last after-get-user-phone" :style="{'opacity': opacityTitle }">
                        Расчет стоимости <span class="accent">сформирован</span>
                    </div>
                    <div class="progress-circle">
                        <svg class="progress-ring" height="118" width="118">
                            <circle class="progress-ring__circle" cx="59" cy="59" fill="transparent" r="52" stroke="#DCB52A" stroke-width="13" style="stroke-dasharray: 326.726, 326.726; stroke-dashoffset: 326.726;"></circle>
                        </svg>
                        <div class="progress-circle__value" v-if="!progressAnimationComplete">{{ this.percent + "%" }}</div>
                        <img alt="complete" class="progress-circle__complete" v-if="progressAnimationComplete" :src="require('../../assets/images/get-user-phone-complete.svg')">
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="quiz__conacts-sm hidden_desctop">
                        <div class="title">Система автоматически рассчитала стоимость Вашего ремонта. Вы получите его сразу после ввода номера телефона.</div>
                        <div class="plus_text flex">
                            <img :src="require('../../assets/images/white_plus.svg')" class="white_plus" alt="plus">
                            <div class="text">получите <b>гарантированный<br> подарок</b></div>
                        </div>
                        <div class="small__text">
                            <div class="text">каталог выполненных ремонтов с&nbsp;фотографиями и расчетом стоимости в квартирах, как у Вас</div>
                            <div class="header_gift__img">
                                <img :src="require('../../assets/images/download_icon.svg')" class="download_icon" alt="download_icon">
                                <img alt="alt" class="header_gift__img-img img-responsive" :src="require('../../assets/images/h_book.png')">
                            </div>
                        </div>
                    </div>

                    <div class="result_phone-img">
                        <div class="get-user-phone__iphone lazy" :style="{backgroundImage: 'url('+require('../../assets/images/iphone.png')+')'}">
                            <video class="video_result" :src="require('../../assets/images/result.mp4')" playsinline="" loop="loop" muted="muted" preload="auto" autoplay="autoplay">
                                <source :src="require('../../assets/images/result.mp4')" type="video/mp4">
                            </video>
                        </div>
                    </div>
                    <div class="result_phone-text"><b>Посмотрите на видео как выглядит расчет стоимости по результатам теста</b></div>
                </div>

                <div class="col-lg-7">
                    <div class="get-user-phone">

                        <div class="pos-relative">
                            <p class="get-user-phone__title">Куда вам удобнее получить точный расчет  и каталог выполненных ремонтов?</p>

                            <div class="messengers flex">
                                <div class="input_parent form_radio messenger">
                                    <label for="result-checkbox-1">
                                        <img :src="require('../../assets/images/whatsapp.svg')" class="mess_icon " :class="{'active': selectedMessanger == 'WhatsApp' }" alt="whatsapp">
                                        <input class="question-6-radio" id="result-checkbox-1" name="Месенджер" type="radio" value="WhatsApp" v-model="selectedMessanger">
                                        <span class="form_radio__label-text form_radio__label-text--big-font">WhatsApp</span>
                                    </label>
                                </div>

                                <div class="input_parent form_radio messenger">
                                    <label for="result-checkbox-2">
                                        <img :src="require('../../assets/images/telegram.svg')" class="mess_icon" :class="{'active': selectedMessanger == 'Telegram' }" alt="telegram">
                                        <input class="question-6-radio" id="result-checkbox-2" name="Месенджер" type="radio" value="Telegram" v-model="selectedMessanger">
                                        <span class="form_radio__label-text form_radio__label-text--big-font">Telegram</span>
                                    </label>
                                </div>

                                <div class="input_parent form_radio messenger">
                                    <label for="result-checkbox-3">
                                        <img :src="require('../../assets/images/viber.svg')" class="mess_icon" :class="{'active': selectedMessanger == 'Viber' }" alt="viber">
                                        <input class="question-6-radio" id="result-checkbox-3" name="Месенджер" type="radio" value="Viber" v-model="selectedMessanger">
                                        <span class="form_radio__label-text form_radio__label-text--big-font">Viber</span>
                                    </label>
                                </div>

                            </div>

                            <div class="get-user-phone__description"><b>+ персональная скидка от нашей компании</b>  будет закреплен<span class="a">а</span> за вашим номером</div>
                            <div class="get-user-phone-btns">
                                <input type="text" class="get-user-phone__input js-question-7-name" name="" v-model="name" placeholder="Ваше имя">
                                <input type="tel" name="Телефон" class="get-user-phone__input js-phone-input js-question-7-phone" v-model="phone"  v-mask="{mask: '+7 (999) 999-99-99', greedy: true, 'digits': 9}" placeholder="+7 (___) ___-__-__">
                                <div class="get-user-phone__button--wrap">
                                    <span class="p_contact--error" v-if="errorText">
                                        {{ errorText }}
                                    </span>
                                    <button type="button" class="get-user-phone__button" @click.prevent="submitForm" :class="{'disabled': checkError}" >Получить расчет >></button></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            animationStart: false,
            progressAnimationComplete: false,
            progress_intervalID: null,
            percent: 0,

            errorInterval: null,
            errorText: "",
            selectedMessanger: 'WhatsApp'
        }
    },
    mounted() {


        window.addEventListener('scroll', ()=>{
            this.circleAnimationStart()
        });

        this.$nextTick(()=>{
            this.circleAnimationStart()
        })
    },
    computed: {
        phone: {
            get() {
                return this.$store.state.form['phone']
            },
            set(value) {
                this.$store.commit('setValue', {
                    'field': 'phone',
                    'value': value,
                })
            }
        },
        name: {
            get() {
                return this.$store.state.form['name']
            },
            set(value) {
                this.$store.commit('setValue', {
                    'field': 'name',
                    'value': value,
                })
            }
        },

        opacityTitle() {
            return this.progressAnimationComplete ? '1' : '0'
        },
        isDisabled(){
            return true;
        },

        checkError(){
            let this_val = this.phone || '';
            this_val = this_val.replace(/-/gi, '');
            this_val = this_val.replace(/_/gi, '');
            this_val = this_val.replace(/\+/gi, '');
            this_val = this_val.replace(/\)/gi, '');
            this_val = this_val.replace(/\(/gi, '');
            this_val = this_val.replace(/\s/g, '');
            if(this_val.length !== 11){
                return true;
            }else{
                return false;
            }
        },
    },
    methods: {

        circleAnimationStart(){
            let rectEl = document.querySelector(".get-user-phone");
            if(!rectEl) return false;

            let rect = rectEl.getBoundingClientRect(),
                circleVisiblePosition = rect.top + window.scrollY - 100

            if(this.animationStart == false){
                if( ( window.scrollY + window.innerHeight ) >  circleVisiblePosition){
                    this.progress_intervalID = setInterval(()=>{
                        this.setProgress()
                    }, 30);
                    this.animationStart = true;
                }
            }
        },
        setProgress(){
            let circle = document.querySelector('circle'),
                radius = circle.r.baseVal.value,
                circumference = radius * 2 * Math.PI;

            this.percent++;
            if(this.percent<=100){

                const offset = circumference - this.percent / 100 * circumference;
                if(offset>=0){
                    circle.style.strokeDashoffset = offset;
                }
            }

            else{
                clearInterval(this.progress_intervalID);
                // $('.js-quiz--result').addClass('quiz-result--locked--animated').show();
                this.progressAnimationComplete = true;
                // $('.quiz__footer').hide();
            }

        },

        submitForm() {
            if(this.checkError) {
                this.errorText = 'Чтобы увидеть результат, введите ваш номер телефона';
                this.errorInterval = setTimeout(()=>{
                    this.errorText = '';
                }, 3000);
                return false;
            }

            this.$http.post('/email-send.php', {form: this.$store.state.form}, {emulateJSON: true}).then(()=>{

                this.$store.commit('setSubmitted', true)

                $('body').removeClass('result_close');
                $('.quiz__footer').show();

                $('.js-quiz--result').animate({
                    top: '0px'
                },'slow', ()=>{

                    $('.js-quiz-questions').hide();
                    $('.js-quiz--result').css('position', 'relative');
                    //$('.quiz-bg').removeClass('quiz-bg--last-question').addClass('quiz-bg--result');
                    $('.cards-wrapper').show();

                });

                $('html, body').animate({
                    scrollTop: $(".cards-wrapper").offset().top
                }, 100);
            }, ()=>{
                this.errorText = 'Ошибка отправки формы!';
                this.errorInterval = setTimeout(()=>{
                    this.errorText = '';
                }, 3000);
                return false;
            });



        }
    }
}
</script>

<style>
.get-user-phone__iphone{
    position: relative;
    width: 330px;
    padding-top: 143%;
    background-size: contain;
    background-repeat: no-repeat;
}
.get-user-phone__iphone video{
    position: absolute;
    left: 12%;
    width: 64%;
    height: 80%;
    top: 11%;
    border: 2px solid #fff;
    border-radius: 0 0 20px 20px;
    right: 12%;
}
@media(max-width:1199px){
    .get-user-phone__iphone{
        padding-top: 163%;
    }
}
@media(max-width:992px){
    .get-user-phone__iphone {
        position: relative;
        width: 258px;
        padding-top: 0;
        background-size: contain;
        background-repeat: no-repeat;
        height: 452px;
        margin: auto;
    }
    .get-user-phone__iphone video {
        left: 14%;
        max-width: 67%;
    }
}
</style>