<template>
    <div class="qiuz_wrap">
        <div class="container">

            <div class="row">
                <div class="col-12 no_padding">
                    <div class="quiz-bg " :class="{'quiz-bg--last-question': question.isFinish && !isSubmited, 'quiz-bg--result': isSubmited}">

                        <div class="cards-wrapper">
                            <div class="row">
                                <div class="col-lg-10 offset-lg-1">
                                    <div class="quiz js-quiz-questions">

                                        <div class="quiz-border">

                                            <div class="quiz__header" v-if="!question.isFinish">
                                                <div class="quiz__header-text">
                                                    <strong>Расчитайте стоимость ремонта вашей квартиры,</strong>
                                                    ответив на 6 вопросов за 1 минуту
                                                </div>
                                            </div>

                                            <!-- Questions -->
                                            <div class="quiz__questions" style="height: auto;">

                                                <div class="quiz__question" v-if="!question.isFinish">

                                                    <div class="quiz__header-steps">

                                                        <div v-for="(step, key) in steps" class="quiz__header-steps-step" :class="{ 'quiz__header-steps-step--active': key <= questionIndex }">
                                                        <span class="quiz__header-steps-step-text" v-if="key == questionIndex">
															Вопрос {{ questionIndex + 1 }} из {{ countQuestions }}
														</span>
                                                        </div>
                                                    </div>



                                                    <div class="row quiz__question-question-body" >

                                                        <Question :question="question" :question-index="questionIndex" @next-page="next" @prev-page="prev"  :show-prev="questionIndex > 0" v-if="!question.isFinish" :is-valid="isValid"> </Question>

                                                        <div class="col-lg-5" v-if="!question.hideSidebar">
                                                            <Sidebar :steps="steps" :question-index="questionIndex" class="ostalos-voprosov"></Sidebar>
                                                        </div>

                                                    </div>
                                                </div>

                                                <Finish v-else></Finish>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Result -->

                        <div class="quiz quiz--result quiz-result js-quiz--result " :class="{'quiz-result--locked': !isSubmited, 'quiz-result--locked--animated': !isSubmited, 'quiz-result--unlocked': isSubmited }" v-if="question.isFinish">
                            <div class="quiz-border">

                                <div class="quiz-result__content">
                                    <div class="row">
                                        <div class="col-lg-10 offset-lg-1">
                                            <div class="quiz-result__title__container">
                                                <div class="quiz-result__title">
                                                    Точный расчет стоимости ремонта вашей квартиры
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <!--- Menu  -->

                                    <StepEnd></StepEnd>
                                </div>

                            </div>
                        </div>
                        <!-- <div class="quiz quiz--result quiz-result js-quiz--result quiz-result--unlocked" id="result_stroyu_sam" style="top: 0px; position: relative; display: block;"> -->

                    </div>


                </div>

            </div>

            <div class="bottom-menu">
                <div class="js-bottom-menu__slide--2 js-bottom-menu__slide" style="">
                    <div class="bottom-menu__header">
                        <button class="bottom-menu__btn-back" v-if="questionIndex > 0" @click="prev"><img :src="require('../../assets/images/arr_next_btn_botom.svg')" alt="next">назад</button>
                        <div class="bottom-menu__btn-next--wrap"><button class="bottom-menu__btn-next" @click="next" :disabled="!isValid">Дальше<img :src="require('../../assets/images/arr_next_btn.svg')" alt="next"></button></div>
                    </div>
                </div>

            </div>

            <div class="modal_window success" :id="info.key" v-for="info in infoboxes">
                <div class="thank_you">
                    <div class="thank_you-text">
                        <div class="thank_you-titile">{{info.title}}</div>
                        <div v-html="info.text">

                        </div>
                    </div>
                </div>
            </div>


            <div class="modal_window success" id="quiz_thank_you" style="display: none;">
                <div class="thank_you">
                    <div class="thank_you-text">
                        <div class="thank_you-titile text-center">Спасибо за заявку!</div>
                        <div class="text-center">
                            Мы свяжемся с Вами в ближайшее время
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Question from "@/blocks/quiz/Question";
import Finish from "@/blocks/quiz/Finish";
import Sidebar from "@/blocks/quiz/Sidebar";
import StepEnd from "@/blocks/quiz/StepEnd";
import validators from "@/blocks/quiz/validators";
let steps = require('./steps').default

export default {
    components: {StepEnd, Sidebar, Question, Finish},
    data(){
        return {
            stepsConfig: steps,
            questionIndex: 0,
            isActive: false,

            infoboxes: [
                {
                    key: 'info_1',
                    title: 'Ремонт отдельных помещений',
                    text: '<p>Нужно отремонтировать часть квартиры: одну или несколько жилых комнат, только кухню, только санузел. </p><p>Либо нужны определенные работы: замена потолков либо напольного покрытия во всей квартире.</p>'
                },
                {
                    key: 'info_2',
                    title: 'Ремонт всей квартиры',
                    text:  '            <p>Нужно отремонтировать всю квартиру и выполнить практически все работы.</p>\n' +
                        '            <p>Комплексный ремонт выгоднее на 20%: материалы закупаются сразу на все работы (так дешевле) и используются\n' +
                        '                экономичнее.</p>'

                },
                {
                    key: 'info_3',
                    title: 'Другие помещения',
                    text: '            <p>Это может быть:</p>\n' +
                        '            <p><small>— балкон</small></p>\n' +
                        '            <p><small>— лоджия</small></p>\n' +
                        '            <p><small>— коридор</small></p>\n' +
                        '            <p><small>— кладовка</small></p>\n' +
                        '            <p><small>— гардеробная и пр.</small></p>\n'
                }
            ]
        }
    },

    mounted() {
        console.log('quiz init');
    },

    computed: {
        isValid(){
            let result = true;

            if(this.question.validateCustom) {
                let $form = this.$store.state.form;
                switch (this.question.validateCustom){
                    case "step4":
                        return !!$form['Демонтаж'] || !!$form['Перегородки'] || !!$form['Потолки'] || !!$form['Стены[]'] || !!$form['Электрика'] || !!$form['Ремонтные работы свой вариант']
                }
                return false;
            }

            let fields = this.question.fields || []

            fields.forEach((field)=>{
                let value = this.$store.state.form[field.name]
                if(field.validator && validators[field.validator]) {
                    if(validators[field.validator](value, this.$store) !== true){
                        result = false
                    }
                }

                if(value == field.value && field.sub) {
                    field.sub.forEach((fieldSub)=>{
                        let valueSub = this.$store.state.form[fieldSub.name]

                        if(fieldSub.validator && validators[fieldSub.validator]) {
                            if(validators[fieldSub.validator](valueSub) !== true){
                                result = false
                            }
                        }
                    })
                }

            })

            return result;
        },

        isSubmited(){
            return this.$store.state.isSubmited
        },
        steps() {
            let config = this.stepsConfig,
                results = [];

            config.forEach((question)=>{
                if(question.func) {
                    switch (question.func){
                        case 'step3':
                            results.push(this.buildStep3())
                            break;
                        case 'step4':
                            results.push(this.buildStep4())
                            if(question.id == 4 && this.$store.state.form['Тип ремонта'] == 'Ремонт всей квартиры') {
                                results.push(this.questionPrise())
                            }
                            break;
                    }
                } else {
                    results.push(question)

                }
            })
            return results;
        },
        //
        countQuestions(){
            return this.steps.length;
        },
        question(){
            return this.steps[this.questionIndex]
        },
        maxIndex(){
            return this.steps.length - 1;
        },

        trackStyle(){
            return {
                width: (((this.questionIndex+1)*100) / quiz.questions.length)+'%'
            }
        }
    },
    methods: {
        restart: function(){
            this.questionIndex=0;
            this.userResponses=Array(this.quiz.questions.length).fill(null);
        },
        selectOption: function(index) {
            Vue.set(this.userResponses, this.questionIndex, index);
            //console.log(this.userResponses);
        },
        next: function() {

            if (this.questionIndex < this.maxIndex) {
                this.questionIndex++;
            } else if(this.questionIndex == this.maxIndex) {

            }
        },

        prev: function() {
            if (this.steps.length > 0) this.questionIndex--;
        },

        /**
         *
         * @returns {{id: number, title: string, fields: [{sub: [{formatter: string, defaultValue: string, name: string, validator: string, id: string, placeholder: string, type: string}], name: string, validator: string, id: string, label: string, type: string, value: string}, {sub: [{formatter: string, defaultValue: string, name: string, validator: string, id: string, placeholder: string, type: string}], name: string, validator: string, id: string, label: string, type: string, value: string}, {sub: [{formatter: string, defaultValue: string, name: string, validator: string, id: string, placeholder: string, type: string}], name: string, validator: string, id: string, label: string, type: string, value: string}, {sub: [{formatter: string, defaultValue: string, name: string, validator: string, id: string, placeholder: string, type: string}], name: string, validator: string, id: string, label: string, type: string, value: string}]}|{id: number, title: string, fields: [{sub: [{formatter: string, defaultValue: string, name: string, validator: string, id: string, placeholder: string, type: string}], name: string, validator: string, id: string, label: string, type: string, value: string}, {sub: [{formatter: string, defaultValue: string, name: string, validator: string, id: string, placeholder: string, type: string}], name: string, validator: string, id: string, label: string, type: string, value: string}, {sub: [{formatter: string, defaultValue: string, name: string, validator: string, id: string, placeholder: string, type: string}], name: string, validator: string, id: string, label: string, type: string, value: string}]}}
         */
        buildStep4(){

            let commm = [],
                pol=[
                    {
                        id: 'step_4_radio_81',
                        type: 'checkbox',
                        name: 'Пол[]',
                        label: 'Сделать выравнивание или стяжку',
                        value: 'Сделать выравнивание или стяжку',
                    },

                    {
                        id: 'step_4_radio_82',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Положить плитку (керамическую или керамогранит)',
                        value: 'Положить плитку',
                    },
                    {
                        id: 'step_4_radio_83',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Без замены напольного покрытия',
                        value: 'Без замены напольного покрытия',
                    },
                    {
                        id: 'step_4_radio_84',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Положить ламинат',
                        value: 'Положить ламинат',
                    },
                    {
                        id: 'step_4_radio_85',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Положить паркетную доску',
                        value: 'Положить паркетную доску',
                    },
                    {
                        id: 'step_4_radio_86',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Положить линолеум',
                        value: 'Положить линолеум',
                    },
                    {
                        id: 'step_4_radio_87',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Положить ковролин',
                        value: 'Положить ковролин',
                    },
                ],
                stena= [
                    {
                        id: 'step_4_radio_51',
                        type: 'checkbox',
                        name: 'Стена[]',
                        label: 'Выровнять стены',
                        value: 'Выровнять стены',
                    },

                    {
                        id: 'step_4_radio_52',
                        type: 'radio',
                        name: 'Стены[]',
                        label: 'Покрасить стены',
                        value: 'Покрасить стены',
                    },
                    {
                        id: 'step_4_radio_53',
                        type: 'radio',
                        name: 'Стены[]',
                        label: 'Поклеить обои',
                        value: 'Поклеить обои',
                    },
                    {
                        id: 'step_4_radio_56',
                        type: 'radio',
                        name: 'Стены[]',
                        label: 'Сделать декоративное покрытие (венецианскую штукатурку)',
                        value: 'Сделать декоративное покрытие (венецианскую штукатурку)',
                    },
                ],
                form = this.$store.state.form;

            if(form['Тип помещения'] == 'Санузел') {
                stena = [


                    {
                        id: 'step_4_radio_52',
                        type: 'radio',
                        name: 'Стены[]',
                        label: 'Покрасить стены',
                        value: 'Покрасить стены',
                    },
                    {
                        id: 'step_4_radio_53',
                        type: 'radio',
                        name: 'Стены[]',
                        label: 'Укладка плитки',
                        value: 'Укладка плитки',
                    },
                    {
                        id: 'step_4_radio_56',
                        type: 'radio',
                        name: 'Стены[]',
                        label: 'ПВХ панели',
                        value: 'ПВХ панели',
                    },
                ]

                pol=[
                    {
                        id: 'step_4_radio_81',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Положить плитку (керамическую или керамогранит)',
                        value: 'Положить плитку',
                    },
                    {
                        id: 'step_4_radio_82',
                        type: 'radio',
                        name: 'Полы[]',
                        label: 'Без замены напольного покрытия',
                        value: 'Без замены напольного покрытия',
                    }
                ]

                commm = [
                    {
                        id: 'step_4_checkbox_9',
                        type: 'checkbox-fake',
                        name: 'Коммуникации',
                        label: 'Коммуникации',
                        value: 'Да',
                        sub: [
                            {
                                id: 'step_4_radio_91',
                                type: 'radio',
                                name: 'Коммуникации',
                                label: 'Замена водопровода',
                                value: 'Замена водопровода',
                            },

                            {
                                id: 'step_4_radio_92',
                                type: 'radio',
                                name: 'Коммуникации',
                                label: 'Замена канализации',
                                value: 'Замена канализации',
                            }
                        ],
                    },

                    {
                        id: 'step_4_checkbox_10',
                        type: 'checkbox-fake',
                        name: 'Сантехника',
                        label: 'Сантехника',
                        value: 'Да',
                        sub: [
                            {
                                id: 'step_4_radio_101',
                                type: 'checkbox',
                                name: 'Унитаз',
                                label: 'Унитаз',
                                value: 'Установка унитаза',
                            },

                            {
                                id: 'step_4_radio_102',
                                type: 'checkbox',
                                name: 'Ванная',
                                label: 'Ванная',
                                value: 'Установка ванной',
                            },

                            {
                                id: 'step_4_radio_103',
                                type: 'checkbox',
                                name: 'Раковина',
                                label: 'Раковина',
                                value: 'Установка раковины',
                            },
                            {
                                id: 'step_4_radio_104',
                                type: 'checkbox',
                                name: 'Душевая конструкция',
                                label: 'Душевая конструкция',
                                value: 'Установка душевой конструкции',
                            },

                            {
                                id: 'step_4_radio_105',
                                type: 'checkbox',
                                name: 'Инсталляция',
                                label: 'Инсталляция',
                                value: 'Установка инсталяции',
                            },

                            {
                                id: 'step_4_radio_106',
                                type: 'checkbox',
                                name: 'Сместители',
                                label: 'Сместители',
                                value: 'Установка смесителей',
                            },

                            {
                                id: 'step_4_radio_107',
                                type: 'checkbox',
                                name: 'Полотенцесушитель',
                                label: 'Полотенцесушитель',
                                value: 'Установка полотенцесушителя',
                            },

                            {
                                id: 'step_4_radio_108',
                                type: 'checkbox',
                                name: 'Водонагреватель',
                                label: 'Водонагреватель',
                                value: 'Установка водонагревателя',
                            },

                            {
                                id: 'step_4_radio_109',
                                type: 'checkbox',
                                name: 'Техника: стиральная, сушильная машина',
                                label: 'Техника: стиральная, сушильная машина',
                                value: 'Установка техники\n' +
                                    'стиральная, сушильная машина',
                            }
                        ],
                    },
                ]
            } else {
                commm = [
                    {
                        id: 'step_4_checkbox_7',
                        type: 'checkbox-fake',
                        name: 'Другое. Свой вариант',
                        label: 'Другое. Свой вариант',
                        validator: 'step4',
                        sub: [

                            {
                                id: 'step_4_textarea_7',
                                type: 'textarea',
                                name: 'Ремонтные работы свой вариант',
                                placeholder: 'Напишите свой вариант',
                            }
                        ],
                    },
                ];
            }

            return  {
                'id': 4,
                'title': 'Что нужно сделать ?',
                validateCustom: 'step4',
                'fields': [
                    {
                        id: 'step_4_checkbox_1',
                        type: 'checkbox',
                        name: 'Демонтаж',
                        label: 'Демонтаж',
                        value: 'Да',
                        validator: 'step4'
                    },
                    {
                        id: 'step_4_checkbox_2',
                        type: 'checkbox',
                        name: 'Перегородки',
                        label: 'Перегородки',
                        value: 'Да',
                        validator: 'step4'
                    },
                    {
                        id: 'step_4_checkbox_3',
                        type: 'checkbox-fake',
                        name: 'Потолки',
                        label: 'Потолки',
                        value: 'Да',
                        validator: 'step4',
                        sub: [
                            {
                                id: 'step_4_radio_41',
                                type: 'radio',
                                name: 'Потолки',
                                label: 'Установить натяжные потолки',
                                value: 'Установить натяжные потолки',
                            },
                            {
                                id: 'step_4_radio_42',
                                type: 'radio',
                                name: 'Потолки',
                                label: 'Установить подвесные потолки (гипсокартон)',
                                value: 'Установить подвесные потолки (гипсокартон)',
                            },
                            {
                                id: 'step_4_radio_43',
                                type: 'radio',
                                name: 'Потолки',
                                label: 'Покрасить потолки',
                                value: 'Покрасить потолки',
                            },
                        ],
                    },

                    {
                        id: 'step_4_checkbox_5',
                        type: 'checkbox-fake',
                        name: 'Стены',
                        label: 'Стены',
                        value: 'Да',
                        validator: 'step4',
                        sub: stena,
                    },
                    {
                        id: 'step_4_checkbox_8',
                        type: 'checkbox-fake',
                        name: 'Пол',
                        label: 'Пол',
                        value: 'Да',
                        validator: 'step4',
                        sub: pol,
                    },



                    {
                        id: 'step_4_checkbox_6',
                        type: 'checkbox-fake',
                        name: 'Электрика',
                        label: 'Электрика',
                        validator: 'step4',
                        sub: [

                            {
                                id: 'step_4_radio_61',
                                type: 'radio',
                                name: 'Электрика',
                                label: 'Монтаж новой электрики',
                                value: 'Монтаж новой электрики',
                            },
                            {
                                id: 'step_4_radio_62',
                                type: 'radio',
                                name: 'Электрика',
                                label: 'Замена старой электрики',
                                value: 'Замена старой электрики',
                            }
                        ],
                    },
                    ...commm,
                ]
            }
        },

        buildStep3(){
            if(this.$store.state.form['Тип ремонта'] == 'Ремонт всей квартиры') {
                return {
                    id: 3,
                    title: 'Сколько санузлов в Вашей квартире ?',
                    'fields': [
                        {
                            id: 'step_32_radio_1',
                            type: 'radio',
                            name:'Сколько санузлов',
                            label: 'Один',
                            value: 'Один санузел',
                            validator: 'required',
                            sub: [
                                {
                                    id: 'step_32_textarea_1',
                                    type: 'textarea',
                                    name: 'Площадь санузлов',
                                    placeholder: 'Укажите площадь санузла (кв.м). Например, 15',
                                    defaultValue: '',
                                    formatter: 'numberCeilingHeight',
                                    validator: 'validateBathroomArea'
                                }
                            ],
                        },

                        {
                            id: 'step_32_radio_2',
                            type: 'radio',
                            name:'Сколько санузлов',
                            label: 'Два',
                            value: 'Два санузла',
                            validator: 'required',
                            sub: [
                                {
                                    id: 'step_32_textarea_2',
                                    type: 'textarea',
                                    name: 'Площадь санузлов',
                                    placeholder: 'Укажите площадь санузла (кв.м). Например, 15',
                                    defaultValue: '',
                                    formatter: 'numberCeilingHeight',
                                    validator: 'validateBathroomArea'
                                }
                            ],
                        },

                        {
                            id: 'step_32_radio_3',
                            type: 'radio',
                            name:'Сколько санузлов',
                            label: 'Три и более',
                            value: 'Три и более санузлов',
                            validator: 'required',
                            sub: [
                                {
                                    id: 'step_32_textarea_3',
                                    type: 'textarea',
                                    name: 'Площадь санузлов',
                                    placeholder: 'Укажите площадь санузла (кв.м). Например, 15',
                                    defaultValue: '',
                                    formatter: 'numberCeilingHeight',
                                    validator: 'validateBathroomArea'
                                }
                            ],
                        }
                    ]
                }
            }

            return {
                id: 3,
                title: 'Какие помещения нужно отремонтировать ?',
                fields: [
                    {
                        id: 'step_31_radio_1',
                        type: 'radio',
                        name: 'Тип помещения',
                        label: 'Одна или несколько жилых комнат',
                        value: 'Одна или несколько жилых комнат',
                        validator: 'required',
                        sub: [
                            {
                                id: 'step_31_textarea_1',
                                type: 'textarea',
                                name: 'Площадь помещения',
                                placeholder: 'Укажите площадь комнат (кв.м). Например, 34',
                                defaultValue: '',
                                formatter: 'numberCeilingHeight',
                                validator: 'validateBathroomArea'
                            }
                        ],
                    },
                    {
                        id: 'step_31_radio_2',
                        type: 'radio',
                        name: 'Тип помещения',
                        label: 'Кухня',
                        value: 'Кухня',
                        validator: 'required',
                        sub: [
                            {
                                id: 'step_31_textarea_2',
                                type: 'textarea',
                                name: 'Площадь помещения',
                                placeholder: 'Укажите площадь кухни (кв.м). Например, 8',
                                defaultValue: '',
                                formatter: 'numberCeilingHeight',
                                validator: 'validateBathroomArea'
                            }
                        ],
                    },
                    {
                        id: 'step_31_radio_3',
                        type: 'radio',
                        name: 'Тип помещения',
                        label: 'Санузел',
                        value: 'Санузел',
                        validator: 'required',
                        sub: [
                            {
                                id: 'step_31_textarea_3',
                                type: 'textarea',
                                name: 'Площадь помещения',
                                placeholder: 'Укажите площадь санузла (кв.м). Например, 4',
                                defaultValue: '',
                                formatter: 'numberCeilingHeight',
                                validator: 'validateBathroomArea'
                            }
                        ],
                    },
                    {
                        id: 'step_31_radio_4',
                        type: 'radio',
                        name: 'Тип помещения',
                        label: 'Другие помещения',
                        value: 'Другие помещения',
                        infobox: 'info_3',
                        validator: 'required',
                        sub: [
                            {
                                id: 'step_31_textarea_4',
                                type: 'textarea',
                                name: 'Площадь помещения',
                                placeholder: 'Укажите площадь помещений (кв.м). Например, 7',
                                defaultValue: '',
                                formatter: 'numberCeilingHeight',
                                validator: 'validateBathroomArea'
                            }
                        ],
                    },
                ]
            }
        },


        questionPrise(){
            return {
                id: 3,
                title: 'Какой подарок Вы хотите получить ?',
                hideSidebar: true,
                'fields': [
                    {
                        id: 'step_11_radio_1',
                        type: 'radio-image',
                        name:'Подарок',
                        label: 'Дизайн-проект',
                        value: 'Дизайн-проект',
                        validator: 'required',
                        image: 'assets/images/gifts/1.jpeg'
                    },

                    {
                        id: 'step_11_radio_2',
                        type: 'radio-image',
                        name:'Подарок',
                        label: 'Натяжные потолки',
                        value: 'Натяжные потолки',
                        validator: 'required',
                        image: 'assets/images/gifts/2.jpeg'
                    },
                    {
                        id: 'step_11_radio_3',
                        type: 'radio-image',
                        name:'Подарок',
                        label: 'Клининг',
                        value: 'Клининг',
                        validator: 'required',
                        image: 'assets/images/gifts/3.jpeg'
                    },

                    {
                        id: 'step_11_radio_4',
                        type: 'radio-image',
                        name:'Подарок',
                        label: 'Персональная скидка',
                        value: 'персональная скидка от нашей компании',
                        validator: 'required',
                        image: 'assets/images/gifts/4.jpeg'
                    },

                ]
            }

        },

    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
.buiding_type-result.vue-typer .char {
    color: #dcb52a;
}
</style>
