<template>
    <div class="" :class="{'col-lg-7': !question.hideSidebar, 'row': question.hideSidebar}">
        <div class="quiz__question-title" :class="{'col-12': question.hideSidebar}">
            {{ question.title }}
        </div>


        <template v-for="(field, key) in question.fields">
            <Radio :key="field.id" :field="field" v-if="field.type == 'radio' "></Radio>

            <div class="col-lg-3 col-md-6 col-6">
                <RadioImage :key="field.id" :field="field" v-if="field.type == 'radio-image' "></RadioImage>
            </div>
            <Checkbox :key="field.id" :field="field" v-if="field.type == 'checkbox' "></Checkbox>
            <CheckboxFake :key="field.id" :field="field" v-if="field.type == 'checkbox-fake' "></CheckboxFake>
        </template>


        <div class="quiz-buttons" :class="{'col-12': question.hideSidebar}">
            <button class="question__prev-btn" v-if="showPrev" @click="prevPage"><img :src="require('@/assets/images/arr_prev_btn.svg')" alt="arr"></button>
            <button class="question__next-btn" :disabled="!isValid" @click="nextPage"><span class="flex aic">Дальше<img :src="require('@/assets/images/arr_next_btn.svg')" alt="arr"></span></button>
        </div>
    </div>
</template>
<script>
import validators from "@/blocks/quiz/validators";
import Radio from "@/blocks/quiz/inputs/radio";
import Checkbox from "@/blocks/quiz/inputs/checkbox";
import CheckboxFake from "@/blocks/quiz/inputs/checkbox-fake";
import RadioImage from "@/blocks/quiz/inputs/radio-image";
export default {
    name: 'Question',
    components: {CheckboxFake, Checkbox, Radio, RadioImage},
    props: ['question', 'showPrev', 'questionIndex', 'isValid'],
    data(){
        return {

        }
    },
    computed: {


    },
    methods: {
        prevPage(){
            this.$emit('prev-page')
        },
        nextPage(){
            if(this.isValid) {
                this.$emit('next-page')
            }
        }
    }
}
</script>