export default [
    {
        'id': 1,
        'title': 'В каком доме нужно сделать ремонт ?',
        'fields': [
            {
                id: 'step_1_radio_1',
                type: 'radio',
                name:'Тип жилья',
                label: 'Новостройка',
                value: 'Новостройка',
                validator: 'required',
                sub: [
                    {
                        id: 'step_1_textarea_1',
                        type: 'textarea',
                        name: 'Высота потолков',
                        placeholder: 'Укажите высоту потолков, если знаете Например, 2.50',
                        defaultValue: '2.5',
                        formatter: 'numberCeilingHeight',
                        validator: 'validateCeilingHeight'


                    }
                ],
            },

            {
                id: 'step_1_radio_2',
                type: 'radio',
                name:'Тип жилья',
                label: 'Вторичка',
                value: 'Вторичка',
                validator: 'required',
                    sub: [
                    {
                        id: 'step_1_textarea_2',
                        type: 'textarea',
                        name: 'Высота потолков',
                        placeholder: 'Укажите высоту потолков, если знаете Например, 2.50',
                        defaultValue: '2.5',
                        formatter: 'numberCeilingHeight',
                        validator: 'validateCeilingHeight'
                    }
                ],
            }
        ]
    },

    {
        'id': 2,
        'title': 'Вам нужен ремонт всей квартиры или отдельных помещений ?',
        'fields': [
            {
                id: 'step_2_radio_1',
                type: 'radio',
                name:'Тип ремонта',
                label: 'Ремонт отдельных помещений',
                value: 'Ремонт отдельных помещений',
                infobox: 'info_1',
                validator: 'required',
            },

            {
                id: 'step_2_radio_2',
                type: 'radio',
                name:'Тип ремонта',
                label: 'Ремонт всей квартиры',
                value: 'Ремонт всей квартиры',
                validator: 'required',

                infobox: 'info_2',
                sub: [
                    {
                        id: 'step_2_textarea_2',
                        type: 'textarea',
                        name: 'Площадь квартиры',
                        placeholder: 'Укажите площадь вашей квартиры (кв.м). Например, 73',
                        defaultValue: '',
                        formatter: 'numberCeilingHeight',
                        validator: 'validateApartmentArea'
                    }
                ],
            }
        ]
    },

    {
        'id': 3,
        'func': 'step3'

    },

    {
        'id': 4,
        'title': 'Что нужно сделать ?',
        validateCustom: 'step4',
        func: 'step4'
    },

    {
        'id': 5,
        isFinish: true
    }
]