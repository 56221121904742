window.$ = window.jQuery = require('jquery')

require('@fancyapps/fancybox')
require('@fancyapps/fancybox/dist/jquery.fancybox.css')
$().fancybox({
  selector : '.social_slider .slick-slide:not(.slick-cloned) .social_slider-item',
  backFocus : false
});
$(document).on('click', '.slick-cloned', function(e) {
  var $slides = $(this)
      .parent()
      .children('.slick-slide:not(.slick-cloned)');
  $slides
      .eq( ( $(this).attr("data-slick-index") || 0) % $slides.length )
      .trigger("click.fb-start", { $trigger: $(this) });
  return false;
});






$('.js-header__cta-button').click(function(event) {
  var quizStartPosition = $('.quiz').offset().top;
  $('html, body').animate({
    scrollTop: quizStartPosition
  }, 500);
});


import Vue from 'vue'
import VueTyperPlugin from 'vue-typer'
var VueResource = require('vue-resource').default;

Vue.use(VueResource);

Vue.http.options.emulateJSON = true;

Vue.use(VueTyperPlugin)


import Inputmask from "inputmask";

let inputmaskPlugin = {
  install: function(Vue, options) {
    Vue.directive('mask', {
      bind: function(el, binding) {
        Inputmask(binding.value).mask(el);
      }
    });
  }
};
Vue.use(inputmaskPlugin)


import App from './blocks/quiz/App.vue'

require('./scss/style.scss')
Vue.config.productionTip = false

import store from './store'

new Vue({
  mounted() {

  },
  store,
  render: function (h) { return h(App) },
}).$mount('#qiuz_wrap')
