import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);


let store = new Vuex.Store({
    state: {
        form: {
            phone: '',
            name: '',
        },
        isSubmited: false,
    },
    mutations: {
        setSubmitted(state, data) {
            state.isSubmited = data
        },
        setValue(state, data) {
            Vue.set(state.form, data.field, data.value)
        }
    }
});

export default store;