<template>
    <div :style="{backgroundImage: 'url('+require('../../assets/images/result_bg.jpeg')+')'} ">

        <div :class="{'cost_calc-title': showPrice, 'ostalos-voprosov__header-text': !showPrice }">
            Расчет стоимости ремонта <br> <vue-typer class="buiding_type-result" :text='stringTypeRoom' :repeat="0" v-if="stringTypeRoom"></vue-typer> в
            <vue-typer class="buiding_type-result" :text='stringType' :repeat="0"></vue-typer>
        </div>



        <div :class="{'cost_calc-main--list': showPrice, 'ostalos-voprosov_main-params': !showPrice}">

            <div class="param_height" :class="{'cost_calc-main--list-item': showPrice, 'printing_item ': !showPrice}" v-if="showHeight">
                <vue-typer class="printed_text" text='Высота потолков (м) - ' :repeat="0"></vue-typer>

                <b class="result-typing accent" v-if="height">
                    <vue-typer :text='height' :repeat="0"></vue-typer>
                </b>
            </div>

            <div class=" param_square_apartments" :class="{'cost_calc-main--list-item': showPrice, 'printing_item ': !showPrice}" v-if="showApartmentArea">
                <span class="printed_text">
                    <vue-typer text='Площадь квартиры (кв.м) — ' :repeat="0"></vue-typer>
                </span>
                <b class="result-typing accent">
                    <span class="printed_text">
                        <vue-typer :text='apartmentArea' :repeat="0"></vue-typer>
                    </span>
                </b>
            </div>

            <div class=" param_square_bathrooms" :class="{'cost_calc-main--list-item': showPrice, 'printing_item ': !showPrice}" v-if="showBathroomArea">
                <span class="printed_text">
                    <vue-typer text='Площадь санузлов (кв.м) — ' :repeat="0"></vue-typer>
                </span>
                <b class="result-typing accent">
                    <span class="printed_text">
                        <vue-typer :text='bathroomArea' :repeat="0"></vue-typer>
                    </span>
                </b>
            </div>
            <div class=" param_square_other_premises" :class="{'cost_calc-main--list-item': showPrice, 'printing_item ': !showPrice}" v-if="showAllArea">
                <span class="printed_text">
                    <vue-typer :text='allAreaTitle' :repeat="0"></vue-typer>
                </span>
                <b class="result-typing accent">
                    <span class="printed_text">
                        <vue-typer :text='allArea' :repeat="0"></vue-typer>
                    </span>
                </b>
            </div>

        </div>


        <div :class="{ 'ostalos-voprosov_dop-params ': !showPrice}" v-if="works.length">

            <div :class="{'cost_calc-title second': showPrice, 'ostalos-voprosov__header-text': !showPrice}">
                Ремонт <div class="premises_type-result">
                <vue-typer class="buiding_type-result" :text="stringTypeRoom ? stringTypeRoom : 'Квартиры' "  :repeat="0"></vue-typer>
            </div>
            </div>


            <div class="dop-params--title" v-if="!showPrice">
                <vue-typer class="buiding_type-result" text='Перечень работ:' :repeat="0"></vue-typer>
            </div>
            <div class="cost_calc-subtitle" v-else>Перечень работ:</div>

            <div class="dop-params--list" :class="{'cost_calc-secondary--list': showPrice}">

                <template v-for="(work, key) in works">
                    <transition name="slide">
                        <div  :id="'dop_param_'+(key+1)" class="dop-params--item flex aic" style="display: flex;">
                            <div class="text">• {{ work.text }}</div>
                            <div class="loader" v-if="!showPrice"><img :src="require('../../assets/images/params_loader.svg')" class="params_loader" alt="loader"></div>
                        </div>
                    </transition>
                </template>
            </div>

        </div>

        <div class="cost_calc-price" v-if="showPrice">
            Итого: от <span class="first_price">{{ calcResult() }}</span> <span class="rub"> <img :src="require('../../assets/images/rub.svg')" class="rub_icon" alt="rub"></span>
        </div>
        <div class="ostalos-voprosov__footer" v-else>
            <div class="ostalos-voprosov__title">По итогам теста Вы получите:</div>
            <ul class="ostalos-voprosov__list">
                <li class="ostalos-voprosov__list-item"><span>1.</span> Точную стоимость ремонта</li>
                <li class="ostalos-voprosov__list-item"><span>2.</span> Подборку готовых ремонтов с&nbsp;ценами</li>
            </ul>
            <strong>Продолжайте отвечать на вопросы</strong>
        </div>




    </div>
</template>

<script>
export default {
    name: 'QuizSidebar',
    props: ['showPrice'],
    computed: {
        stringType(){
            let val = this.$store.state.form['Тип жилья'];
            if(!val) return '';

            switch (val){
                case 'Новостройка':
                    return 'Новостройке';
                case 'Вторичка':
                    return 'Вторичке';
            }
        },

        stringTypeRoom(){
            if(this.showAllArea) {
                switch ( this.$store.state.form['Тип помещения'] ){
                    case 'Одна или несколько жилых комнат':
                        return 'жилых комнат';
                    case 'Кухня':
                        return 'кухни';
                    case 'Санузел':
                        return 'санузла';
                    case 'Другие помещения':
                        return 'помещений';
                }
            }

            if(this.$store.state.form['Тип ремонта'] == 'Ремонт всей квартиры' ){
                return 'квартиры';
            }
        },

        /*** Высота потолков ***/

        showHeight(){
            return !!this.$store.state.form['Тип жилья'];
        },
        height(){
            return this.$store.state.form['Высота потолков'] || '';
        },

        /******/


        showApartmentArea(){
            return this.$store.state.form['Тип ремонта'] == 'Ремонт всей квартиры';
        },
        apartmentArea(){
            return this.$store.state.form['Площадь квартиры'] || '';
        },

        /*****Сколько санузлов */


        showBathroomArea(){
            return this.$store.state.form['Тип ремонта'] == 'Ремонт всей квартиры' && this.$store.state.form['Сколько санузлов'] ;
        },

        bathroomArea(){
            return this.$store.state.form['Площадь санузлов'] || '';
        },

        /******/


        showAllArea(){
            return this.$store.state.form['Тип ремонта'] == 'Ремонт отдельных помещений' && this.$store.state.form['Тип помещения'] ;
        },

        allAreaTitle(){
            switch (this.$store.state.form['Тип помещения'] ){
                case 'Одна или несколько жилых комнат':
                    return 'Площадь жилых комнат (кв.м) — ';
                case 'Кухня':
                    return 'Площадь кухни (кв.м) — ';
                case 'Санузел':
                    return 'Площадь санузла (кв.м) — ';
                case 'Другие помещения':
                    return 'Площадь других помещений (кв.м) — ';

            }
        },

        allArea(){
            return this.$store.state.form['Площадь помещения'] || '';
        },

        /*************/

        works(){
            let res = [],
                $form = this.$store.state.form,
                schema = ['Демонтаж', 'Перегородки', 'Потолки', 'Стена[]',  'Стены[]', 'Пол[]', 'Полы[]',  'Электрика',

                    'Унитаз', 'Ванная', 'Раковина', 'Душевая конструкция', 'Инсталляция', 'Сместители', 'Полотенцесушитель', 'Водонагреватель', 'Техника: стиральная, сушильная машина' ];

            schema.forEach((fieldName)=>{
                if(!!$form[fieldName]) {
                    res.push({
                        'text': $form[fieldName] == 'Да' ? fieldName : $form[fieldName],
                        'show': !!$form[fieldName]
                    })
                }

            })

            return res;
        },


    },
    methods: {

        calcResult(){
            let $form = this.$store.state.form
            // параметры вычислений
            // результат
            let allResult = 0;
            // общая площадь
            let allSquare = 0;
            // жилая площадь
            let zhilSquare = 0;
            // стоимость ремонта в жилых помещениях
            let zhilSquareCost = 6500;
            let zhilSquareRes = 0;
            let bathroomsSquareRes = 0;
            let bathroomsSquareCost = 50000;
            let premisesSquare = 0;
            let premisesSquareCost = 0;
            let skidka = 0;

            // площадь санузлов
            let bathroomsSquare = parseFloat($form['Площадь санузлов']) || 0

            // площадь жилых помещений (частичный ремонт)
            premisesSquare = parseFloat($form['Площадь помещения']) || 0

            if(premisesSquare >= 10){
                premisesSquareCost = 7500;
            }else{
                premisesSquareCost = 12500;
            }

            ////////////////////////////////////////
            // расчет стоимости комлексный ремонт
            ////////////////////////////////////////
            allSquare = parseFloat($form['Площадь квартиры']) || 0


            zhilSquare += (allSquare - bathroomsSquare);
            // console.log('площадь санузлов = ' + bathroomsSquare);
            // console.log('общая площадь = ' + allSquare);
            // console.log('жилая площадь = ' + zhilSquare);

            // потолки
            if($form['Демонтаж']){
                zhilSquareCost += 1500;
                premisesSquareCost += 1500;
            }

            if($form['Потолки'] == 'Установить подвесные потолки (гипсокартон)'){
                zhilSquareCost += 2000;
                premisesSquareCost += 2000;
            }
            if($form['Потолки'] == 'Покрасить потолки'){
                zhilSquareCost += 1800;
                premisesSquareCost += 1800;
            }

            // стены
            if ($form['Стены[]'] == 'Выровнять стены'){
                zhilSquareCost += 2000;
                premisesSquareCost += 2000;
            }
            // question-4-checkbox-3-2
            if($form['Стены[]']=='question-4-checkbox-3-2'){
                zhilSquareCost += 1200;
                premisesSquareCost += 1200;
            }
            if($form['Стены[]']=='question-4-checkbox-3-4'){
                zhilSquareCost += 2000;
                premisesSquareCost += 2000;
            }


            // полы
            if( $form['Пол[]'] == 'Сделать выравнивание или стяжку'){
                zhilSquareCost += 500;
                premisesSquareCost += 500;
            }
            if( $form['Полы[]'] == 'Положить плитку'){
                zhilSquareCost += 1000;
                premisesSquareCost += 1000;
            }
            if($form['Полы[]'] == 'Положить паркетную доску'){
                zhilSquareCost += 300;
                premisesSquareCost += 300;
            }
            if($form['Полы[]'] == 'Положить линолеум'){
                zhilSquareCost += (-200);
                premisesSquareCost += (-200);
            }
            if($form['Полы[]'] == 'Положить ковролин'){
                zhilSquareCost += (-150);
                premisesSquareCost += (-150);
            }

            // Электрика
            if($form['Электрика'] == 'Монтаж новой электрики'){
                zhilSquareCost += 1500;
                premisesSquareCost += 1500;
            }

            ////////////////////////////////////////
            // расчет стоимости частичный ремонт
            ////////////////////////////////////////

            let bathroomSquare = 0;
            let bathroomSquareCost = 40000;

            if($form['Тип помещения'] == 'Санузел'){
                bathroomSquare = parseFloat($form['Площадь помещения']) || 0;
            }

            // потолки
            if($form['Потолки'] == 'Установить натяжные потолки'){
                bathroomSquareCost += 400;
            }
            if($form['Потолки'] == 'Установить натяжные потолки'){
                bathroomSquareCost += (-400);
            }

            // стены
            if( $form['Стены[]'] == 'Укладка плитки'){
                bathroomSquareCost += 2000;

            }
            if($form['Стены[]'] == 'ПВХ панели'){
                bathroomSquareCost += (-10000);
            }

            // Коммуникации
            if( $form['Электрика'] == 'Монтаж новой электрики' ){
                bathroomSquareCost += 10000;
            }

            if( $form['Полы[]'] == 'Без замены напольного покрытия'){
                bathroomSquareCost += (-5000);
            }

            if($form['Коммуникации'] == 'Замена водопровода'){
                bathroomSquareCost += 30000;
            }
            if( $form['Коммуникации'] == 'Замена канализации'){
                bathroomSquareCost += 10000;
            }

            // Сантехника
            let santekhnikaCounter = 0;
            let santekhnikaCost = 0;
            let santekhnikaInput = 	['Унитаз', 'Ванная', 'Раковина', 'Душевая конструкция', 'Инсталляция', 'Сместители', 'Полотенцесушитель', 'Водонагреватель', 'Техника: стиральная, сушильная машина'];

            santekhnikaInput.forEach((val)=>{

                if( $form[val]){
                    santekhnikaCounter++;
                    console.log('сантехника кол-во позиций = ' + santekhnikaCounter);
                }
            })
            if(santekhnikaCounter > 0){
                santekhnikaCost += (santekhnikaCounter * 4000);
                console.log('стоимость сантехники = ' + santekhnikaCost);
            }
            bathroomSquareCost+= santekhnikaCost;

            if($form['Демонтаж']){
                if(bathroomSquare <= 3){
                    bathroomSquareCost += 10000;
                }else{
                    bathroomSquareCost += 20000;
                }
            }

            //premisesSquareCost

            let premisesSquareRes = (premisesSquare * premisesSquareCost);
            // console.log('площадь ж/п = ' + premisesSquare);
            // console.log('цена за метр для ж/п  = ' + premisesSquareCost);
            // console.log('стоимость ремонта ж/п  = ' + premisesSquareRes);

            let bathroomSquareRes = (bathroomSquare * bathroomSquareCost);


            zhilSquareRes = (zhilSquare  * zhilSquareCost);
            bathroomsSquareRes = (bathroomsSquare * bathroomsSquareCost );
            // console.log('стоимость ремонта 1 м² жилых помещений = ' + zhilSquareCost);
            // console.log('стоимость ремонта  жилых помещений = ' + zhilSquareRes);
            // console.log('стоимость ремонта санузлов = ' + bathroomsSquareRes);

// 	if($('#question-2-radio-2').prop('checked')){
// 		allResult += zhilSquareRes + bathroomsSquareRes;
// 		if(allResult > 0){
// 			skidka = allResult * .2;
// 			$('.first_price').text(allResult - skidka);
// 			$('.second_price').text(allResult);
// 			$("[name='Итоговая стоимость']").val((allResult - skidka) + '  -  ' +allResult);

// 		}
// 	}
            if( $form['Тип ремонта'] == 'Ремонт всей квартиры' ){
                allResult += zhilSquareRes + bathroomsSquareRes;
                if(allResult > 0){
                    console.log('результат = ' + allResult);
                    skidka = allResult / 1.5;
                    console.log('результат /1.5 = ' + skidka);

                    return Math.round(skidka);
                    //   $('.first_price').text(Math.round(skidka));
// 			$('.second_price').text(allResult);
                    //  $("[name='Итоговая стоимость']").val(Math.round(skidka));

                }
            }

            // 	if($('#question-2-radio-1').prop('checked')){
            // 		if($('#question-3-radio-3').prop('checked')){
            // 			allResult += bathroomSquareRes;
            // 		}else{
            // 			allResult += premisesSquareRes;
            // 		}
            // 		if(allResult > 0){
            // 			skidka = allResult * .2;
            // 			$('.first_price').text(allResult);
            // 			$('.second_price').text(allResult + skidka);
            // 			$("[name='Итоговая стоимость']").val(allResult + '  -  ' + (allResult + skidka));
            // 		}

            // 	}
            if( $form['Тип ремонта'] == 'Ремонт отдельных помещений'){
                if ( $form['Тип помещения'] == 'Санузел'){
                    allResult += bathroomSquareRes;
                }else{
                    allResult += premisesSquareRes;
                }
                if(allResult > 0){
                    console.log('результат = ' + allResult);
                    skidka = allResult / 1.5;
                    console.log('результат /1.5 = ' + skidka);
                    return Math.round(skidka);

                    //$('.first_price').text(Math.round(skidka));
// 			$('.second_price').text(allResult + skidka);
                    //$("[name='Итоговая стоимость']").val(Math.round(skidka));
                }

            }
            // console.log('стоимость всего ремонта = ' + allResult);


        }
    }
}
</script>

<style>

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
.accent .vue-typer .typed {
    color: #DCB52A!important;
}
</style>